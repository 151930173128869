import React  from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Smpmenubtn from "./smpmenubtn"


const Smpmenu = () => (
  <StaticQuery
    query={graphql`
    {
      datoCmsHome {
        seo {
          title
          description
          twitterCard
        }
        icon {
            url
            fluid(maxWidth: 200, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
              ...GatsbyDatoCmsFluid
            }
          }
      }
      datoCmsFooter {
        id
        image {
          fluid(maxWidth: 100, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        copyrignt
      }
    }
    `}
    
    render={data=> {


      const homedata = data.datoCmsHome

      const drawerOpen = () => {
        const drawer = document.querySelector('.lp_container')
        drawer.classList.toggle("open")
      }
      const drawerClose = () => {
        const drawer = document.querySelector('.lp_container')
        drawer.classList.remove("open")
      }

      
      return (
        <>
        <div className="smp_menu">
          <a href="https://www.slingbelt.com/cart"><Img fluid={homedata.icon[4].fluid} alt="カート" /><span>カート</span></a>
          <a href="https://www.slingbelt.com/mypage/login"><Img fluid={homedata.icon[3].fluid} alt="ログイン" /><span>ログイン</span></a>
          <a href="https://www.slingbelt.com/user_data/checkeditemlist"><Img fluid={homedata.icon[8].fluid} alt="見た物リスト" /><span>見た物リスト</span></a>
          <a href="https://www.slingbelt.com/contact"><Img fluid={homedata.icon[0].fluid} alt="お問合せ" /><span>お問合せ</span></a>
          <button className="drawerbtn" onClick={drawerOpen}><Img fluid={homedata.icon[1].fluid} alt="メニュー" /><span>メニュー</span></button>
	      </div>

        <div className="drawer_menu" >
          <button className="closebtn" onClick={drawerClose}><Img fluid={homedata.icon[9].fluid} alt="閉じる" /></button>
          <div className="drawer_menu_list">
            <div className="drawer_menu_detail">
            <p>カテゴリーから探す</p>

            <Smpmenubtn count="7">スリングベルト</Smpmenubtn>
            <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/detail/5405">25mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5526">50mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5527">75mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5528">100mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5529">150mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5530">200mm幅</a>
              <a href="https://www.slingbelt.com/products/detail/5531">300mm幅</a>

            </div>
            <Smpmenubtn count="10">ラウンドスリング</Smpmenubtn>
            <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/detail/5532">1t用</a>
              <a href="https://www.slingbelt.com/products/detail/5533">2t用</a>
              <a href="https://www.slingbelt.com/products/detail/5534">3t用</a>
              <a href="https://www.slingbelt.com/products/detail/5535">4t用</a>
              <a href="https://www.slingbelt.com/products/detail/5536">5t用</a>
              <a href="https://www.slingbelt.com/products/detail/5537">6t用</a>
              <a href="https://www.slingbelt.com/products/detail/5538">8t用</a>
              <a href="https://www.slingbelt.com/products/detail/5539">10t用</a>
              <a href="https://www.slingbelt.com/products/detail/5540">15t用</a>
              <a href="https://www.slingbelt.com/products/detail/5541">20t用</a>

            </div>
            <Smpmenubtn count="8">ラッシングベルト</Smpmenubtn>
            <div className="acordion_menu">
            <a href="https://www.slingbelt.com/products/list?category_id=27">50mm幅 ブルー</a>
<a href="https://www.slingbelt.com/products/list?category_id=28">50mm幅 オールブラック</a>
<a href="https://www.slingbelt.com/products/list?category_id=193">50mm幅 ホワイト</a>
<a href="https://www.slingbelt.com/products/list?category_id=231">50mm幅 反射レッド</a>
<a href="https://www.slingbelt.com/products/list?category_id=282">50mm幅 イエロー</a>
<a href="https://www.slingbelt.com/products/list?category_id=319">50mm幅 オールステンレス</a>
<a href="https://www.slingbelt.com/products/list?category_id=29">35mm幅 オレンジ</a>
<a href="https://www.slingbelt.com/products/list?category_id=49">耐熱ラッシングベルト</a>

            </div>
            <Smpmenubtn count="5">25mm幅ベルト</Smpmenubtn>
            <div className="acordion_menu">
            <a href="https://www.slingbelt.com/products/list?category_id=46">カムバックルベルト</a>
<a href="https://www.slingbelt.com/products/list?category_id=50">サインベルト</a>
<a href="https://www.slingbelt.com/products/list?category_id=47">タイダウンベルト</a>
<a href="https://www.slingbelt.com/products/list?category_id=48">サブベルト</a>
<a href="https://www.slingbelt.com/products/list?category_id=281">とってちゃん</a>

            </div>
            <a href="https://www.slingbelt.com/products/list?category_id=281">原反・バックル・パーツ</a>

            <Smpmenubtn count="7">燃料ホース</Smpmenubtn>
              <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/list?category_id=59">内層ブレード 定尺</a>
  <a href="https://www.slingbelt.com/products/list?category_id=60">内層ブレード 切り売り</a>
  <a href="https://www.slingbelt.com/products/list?category_id=61">外層ブレード 定尺</a>
  <a href="https://www.slingbelt.com/products/list?category_id=62">外層ブレード 切り売り</a>
  <a href="https://www.slingbelt.com/products/list?category_id=63">補強層なし 定尺</a>
  <a href="https://www.slingbelt.com/products/list?category_id=64">補強層なし 切り売り</a>
  <a href="https://www.slingbelt.com/products/list?category_id=191">ホース用バンド</a>

              </div>
              <Smpmenubtn count="4">ウォーターホース</Smpmenubtn>
              <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/list?category_id=65">内径6mm-19mm</a>
<a href="https://www.slingbelt.com/products/list?category_id=190">内径6mm-19mm 切り売り</a>
<a href="https://www.slingbelt.com/products/list?category_id=66">内径22mm-57mm</a>
<a href="https://www.slingbelt.com/products/list?category_id=67">L型</a>

              </div>
              <Smpmenubtn count="2">高圧洗浄機用ホース</Smpmenubtn>
              <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/list?category_id=72">高圧</a>
<a href="https://www.slingbelt.com/products/list?category_id=73">超高圧</a>

              </div>

              <Smpmenubtn count="2">EVA泥除け</Smpmenubtn>
              <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/list?category_id=75">600W × 500H</a>
<a href="https://www.slingbelt.com/products/list?category_id=74">600W × 900H</a>

              </div>

            <a href="https://www.slingbelt.com/products/list?category_id=14">ショーリングバー</a>
            
            <Smpmenubtn count="3">アウトレット</Smpmenubtn>
              <div className="acordion_menu">
              <a href="https://www.slingbelt.com/products/list?category_id=76">スリングベルト</a>
<a href="https://www.slingbelt.com/products/list?category_id=78">ラウンドスリング</a>
<a href="https://www.slingbelt.com/products/list?category_id=77">ラッシングベルト</a>


              </div>
            <a href="https://www.slingbelt.com/products/list?category_id=318">販売支援商品</a>

            </div>
          </div>
        </div>
      </>
      );
    }}
  />
);

export default Smpmenu