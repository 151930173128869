import React from "react"
import { StaticQuery, graphql } from "gatsby"
// import { Link } from "gatsby"
import Img from "gatsby-image"

const Header = () => (
  <StaticQuery
    query={graphql`
    {
      datoCmsHome {
        seo {
          title
          description
          twitterCard
        }
        copyright
        icon {
          url
          fluid(maxWidth: 350, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
            ...GatsbyDatoCmsFluid
          }
        }
      }
    }
    `}
    render={data=> {
      const homedata = data.datoCmsHome
      return (
        <header>
          <div className="search">
          <form action="https://www.slingbelt.com/products/list" method="post">
            
              <p><label><span>1</span><input type="search" name="name" size="30" maxLength="255" placeholder="何かお探しですか？" id="test" /></label></p>
              <p><button type="submit"><Img fluid={homedata.icon[5].fluid} alt="search" /></button></p>
              </form> 
          </div>
          <div className="logo">
            <h1>
            <a href="https://www.slingbelt.com/"><Img fluid={homedata.icon[6].fluid} alt="スリングベルト.com アヴィエラン"/></a>
            </h1>
          </div>
          <div className="menu">
              <a href="https://www.slingbelt.com/products/list">
              <Img fluid={homedata.icon[7].fluid} alt="商品一覧" /><span>商品一覧</span>
              </a>
              <a href="https://www.slingbelt.com/cart" className="cartbtn">
              <Img fluid={homedata.icon[4].fluid} alt="買い物かご" /><span>買い物かご</span>
              </a>
              <a href="https://www.slingbelt.com/contact">
              <Img fluid={homedata.icon[0].fluid} alt="お問い合わせ" /><span>お問い合わせ</span>
              </a>
              <a href="https://www.slingbelt.com/mypage/login">
              <Img fluid={homedata.icon[3].fluid} alt="ログイン" /><span>ログイン</span>
              </a>
              <a href="https://www.slingbelt.com/mypage/login">
              <Img fluid={homedata.icon[2].fluid} alt="マイページ" /><span>マイページ</span>
              </a>
          </div>
        </header>
      );
    }}
  />
);

export default Header