import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Footer = () => (
  <StaticQuery
    query={graphql`
    {
      datoCmsHome {
        seo {
          title
          description
          twitterCard
        }
        copyright
      }
      datoCmsFooter {
        id
        image {
          fluid(maxWidth: 100, forceBlurhash: false, imgixParams: { auto: "compress", fit: "crop" }) {
            ...GatsbyDatoCmsFluid
          }
        }
        copyrignt
      }
    }
    `}
    render={data=> {
      return (
<footer>
		<p className="footer_title">ショッピングガイド</p>
		<div className="footer_shopping_guide">
			<a href="https://www.slingbelt.com/help/guide#g8">
				<span className="title">会員登録について</span>
				<span className="comment">
					会員登録してご購入いただきますと特典がございます。
					是非、ご購入前に会員登録をご検討ください。
					会員登録済のお客様は、ログインをお願いいたします。 
				</span>
			</a>

			<a href="https://www.slingbelt.com/help/guide#g1">
				<span className="title">送料について</span>
				<span className="comment">
					お買い上げ金額￥6,000以上購入で、弊社が送料を負担いたします。
					￥6,000未満の場合は、送料 ￥1,100が発生いたします。<br />
					※沖縄/離島は、お買上金額に関わらず別途追加送料が発生いたします。
				</span>
			</a>
			<a href="https://www.slingbelt.com/help/guide#g2">
				<span className="title">支払方法について</span>
				<span className="comment">
					･クレジットカード決済
				</span>
        <div className="payicon">
          <span><Img fluid={data.datoCmsFooter.image[6].fluid} alt="VISA" /></span>
					<span><Img fluid={data.datoCmsFooter.image[7].fluid} alt="MASTER" /></span>
					<span><Img fluid={data.datoCmsFooter.image[8].fluid} alt="AMERICAN EXPRESS" /></span>
					<span><Img fluid={data.datoCmsFooter.image[9].fluid} alt="JCB" /></span>

				</div>

				<span className="comment">
					･銀行振込(事前振込)<br />
					･コンビニ決済(番号方式)<br />
				</span>
				<div className="payicon">
					<span><Img fluid={data.datoCmsFooter.image[0].fluid} alt="セブンイレブン" /></span>
					<span><Img fluid={data.datoCmsFooter.image[1].fluid} alt="ファミリーマート" /></span>
					<span><Img fluid={data.datoCmsFooter.image[2].fluid} alt="ローソン" /></span>
					<span><Img fluid={data.datoCmsFooter.image[3].fluid} alt="ミニストップ" /></span>
					<span><Img fluid={data.datoCmsFooter.image[4].fluid} alt="デイリーストア" /></span>
					<span><Img fluid={data.datoCmsFooter.image[5].fluid} alt="セイコー" /></span>

				</div>
				<span className="comment">

					･代金引換(一部商品のみ) <br />
					･請求書払い(法人様のみ) <br />
					をご利用いただけます。
				</span>
			</a>

			<a href="https://www.slingbelt.com/help/guide#g3">
				<span className="title">配送について</span>
				<span className="comment">
					配送業者は「佐川急便」「西濃運輸」「エスラインギフ」となります。
					配送業者の指定は出来ませんので、ご了承ください。
				</span>
			</a>
			
			<a href="https://www.slingbelt.com/help/guide#g3">
				<span className="title">発送について</span>
				<span className="comment">
					原則として、即日または翌営業日に発送いたします。
					ただし、銀行振込(事前振込)、コンビニ決済(番号方式)を選択された場合には、ご入金確認後の発送となります。 
				</span>
			</a>

			<a href="https://www.slingbelt.com/help/guide#g3">
				<span className="title">返品交換について</span>
				<span className="comment">
					返品・交換をご希望される場合には、商品到着日を含め7日以内に、お電話にてご連絡ください。
					ただし、ご使用になられた商品の返品・交換は受付不可とさせていただきます。
				</span>
			</a>
		</div>

		<div className="footer_menu">
			<a href="https://www.slingbelt.com/help/privacy">個人情報保護方針</a>
			<a href="https://www.slingbelt.com/user_data/agreement">利用規約</a>
			<a href="https://www.slingbelt.com/help/tradelaw">特定商取引法の表記</a>
			<a href="https://www.slingbelt.com/user_data/faq">FAQ</a>
			<a href="https://www.slingbelt.com/user_data/sitemap">サイトマップ</a>
			<a href="https://www.slingbelt.com/contact">お問い合わせ</a>
		</div>

		<div className="footer_copyright">
			<p>copyright (c) AVIELAN Corporation all rights reserved.</p>
		</div>
	</footer>
      );
    }}
  />
);

export default Footer