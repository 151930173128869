import React, { useEffect, useRef } from 'react';

import Header from "./header"
import Leftmenu from "./leftmenu"
import Footer from "./footer"
import Seo from "./seo"
import Smpmenu from "./smpmenu"
import "@fontsource/noto-sans-jp"


const Layout = ({children, pagestyle, pathname, seo}) =>{
    const containerName = `lp_container ${pagestyle}`
    const target = useRef(null);
    useEffect(()=>{
      const cb = (entries) => {
        entries.forEach((entry)=>{
          const headcheck = document.querySelector('header')
          if(entry.isIntersecting){
            //クラスの付け替え
            headcheck.classList.add("on")
          }else {
            //クラスの付け替え
            headcheck.classList.remove("on")
          }
        })
      };
      const options = {
        root:null,//ビューポートと交差
        rootMargin: "200px 0px 0px 0px",//上下内側に-10%の地点で処理を実行する
      }
      const io = new IntersectionObserver(cb, options);
      io.observe(target.current)
    },[])
    return (
      <>
        <div className={pagestyle ? containerName: "lp_container"} >
        <Seo postData={seo}/>
        <div className="description" ref={target}></div>
        <Header/>
        <div className="header_description">
          <h1 className="de">スリングベルト・ラウンドスリング・ラッシングベルト製品の販売<br class="sp" />(メーカー直営通販)</h1>
        </div>
        <Leftmenu/>
        <main>
            {children}
            {pathname}
        </main>
        <Footer/>
        <Smpmenu/>

        </div>
      </>
    )
  }
  
  export default Layout;