import React from "react"
// import { Link } from "gatsby"

class LoggingButton extends React.Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
		// コンストラクタの中で明示的にbind
		this.handleClick = this.handleClick.bind(this);
	}
  // This syntax ensures `this` is bound within handleClick.
  // Warning: this is *experimental* syntax.
  handleClick = () => {
    const node = this.myRef.current;
    const nextnode = node.nextElementSibling
    node.classList.toggle("open")
    nextnode.classList.toggle("open")
    const openflg =nextnode.classList.contains("open")
    const count = parseFloat(this.props.count)
    const hight = count * 48
    openflg ? nextnode.style.height = `${hight}px` : nextnode.style.height = `0px`
  }

  render() {
    return (
      <button onClick={this.handleClick} ref={this.myRef}>
        {this.props.children}
      </button>
    );
  }
}

const Smpmenubtn = ({children, count}) => {

  return (
    <>
    <LoggingButton count={count}>{children}</LoggingButton>
    </>
  )
}

export default Smpmenubtn;
